import { useState, useContext } from "react"
import PageWrapper from "./components/library/pageWrapper"
import Header from "./components/header"
import ActionButton from "./components/library/actionButton"
import { AuthContext } from './components/context/auth'
import './components/styles/input.css'

function LoginPage(){
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const auth = useContext(AuthContext)

    async function login()
    {
        try{
            await auth.tryLogin(userName, password)
            setErrorMessage('')
        } catch (ex) {
            setErrorMessage(`Failed to login! ${ex}`)
        }
    }
    return <div style={{marginTop: '8%'}}>
        <PageWrapper>
            <Header justLogo/>
            <div style={{marginTop: '8%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 13}}> 
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginBottom: 40}}>
                    <div style={{marginBottom: 5}}>USER ID</div>
                    <input type="text" value={userName} placeholder={"Enter your User ID"} onChange={(e)=>setUserName(e.target.value)} 
                        className='anInput'
                        style={{height: 50, width: 284, fontSize: 14, paddingLeft: 14}}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginBottom: 40}}>
                    <div style={{marginBottom: 5}}>PASSWORD</div>
                    <input type="password" value={password} placeholder={"Enter your password"} onChange={(e)=>setPassword(e.target.value)}  
                        className='anInput'
                        style={{height: 50, width: 284, fontSize: 14, paddingLeft: 14}}/>
                </div>
                <ActionButton
                    enabled={!!userName && !!password}
                    onClick={login}
                >
                    Log in to platform
                </ActionButton>
                {errorMessage  && 
                    <div style={{color: 'red'}}>
                        !!! {errorMessage}
                    </div>
                }    
            </div>
        </PageWrapper>
    </div>
}
export default LoginPage