export default function SectionBox({ children, style }) {
	const calcStyle={
		minHeight: 130,
		border: '1px solid #ccc',
		boxShadow: '0px 1px 2px rgba(0,0,0,0.1',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		padding: 5,
		paddingLeft: 26,
		paddingRight: 26,
		borderRadius: '5px',
		margin: 15,
		...style
	}
	return (
		<div style={calcStyle}>
			{children}
		</div>
	)
}
