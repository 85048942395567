import CollapsableInformation from "../../library/collapsableInformation"
import TotecTable from "../../library/totecTable"
import {dollarFormatter} from '../../library/formatters'

function ConfirmationMathDetails({orderDetails, relatedDetails}){
    const dataRows = relatedDetails ?
        orderDetails.order.selectedContracts :
        orderDetails.order.selectedContracts.map(deets => ({...deets.originalContractCalculations, Contract_number: deets.Contract_number}))


    const parentOrderDirection = orderDetails.order.direction
    const direction = !relatedDetails ? parentOrderDirection :
        (parentOrderDirection === 'in' ? 'out' : 'in')

    const sharedColumns = [
        { Title: 'METER', key: 'meterNumber', width: '.6fr' },
        {Title: 'Transfer',formatter: ()=>direction}
    ]
    const inDetailColumns = [
        {Title: 'e3m3', key: 'transferAmount'},
        {Title: 'Days', formatter: (r)=>r.numberOfDays},
        {Title: 'FTR/d', formatter: (r) => dollarFormatter(+r.ftrPerDayIn)},
        {Title: 'ITR/d', formatter: (r)=>dollarFormatter(+r.itr)},
        {Title: 'rate diff', formatter: (r)=>dollarFormatter(+r.rateDiffIn)},
        {Title: 'profit share', key:'profitShare'},
        {Title: 'fee/mth', formatter: (r) => dollarFormatter(+r.feePerMthIn)},
        {Title: 'total fee', key: 'formattedTotalFeeIn'},
    ]
    const outDetailColumns = [
		{ Title: 'e3m3', key: 'transferAmount' },
		{ Title: 'days', formatter: (r) => +r.numberOfDays },
		{ Title: 'ftr/mth', formatter: (r) => dollarFormatter(+r.ftrPerMonth) },
		{ Title: 'Take Or Pay', formatter: (r) => dollarFormatter(+r.takeOrPay) },
		{ Title: 'Profit share', key: 'profitShare' },
		{ Title: 'Savings', formatter: (r) => dollarFormatter(+r.savingsOut) },
		{ Title: 'Fee/mth', key: 'formattedTotalFeeOut' },
	]
        
    const detailColumns = direction === 'in' ? inDetailColumns : outDetailColumns
    const columns = [...sharedColumns, ...detailColumns]
    return <CollapsableInformation>
        <div style={{backgroundColor: 'pink', marginTop: 10, marginBotton: 10, padding: 20}}>
            {relatedDetails ? 'For Partner Node' : 'For Company Node'}
            <TotecTable columnDescriptions={columns} keyField={'Contract_number'} dataRows={dataRows}/>
        </div>
    </CollapsableInformation>
}

export default ConfirmationMathDetails
