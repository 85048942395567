export default function TotecTable({ columnDescriptions, dataRows, keyField, dataRowStyle, onClickRow }) {
	onClickRow = onClickRow || (()=>{})
	const gridTemplateColumns = columnDescriptions.map((col) => col.width || '1fr').join(' ')

	function getCellContents(cellDescription, row) {
		if (cellDescription.formatter) return cellDescription.formatter(row)
		return row[cellDescription.key]
	}

	return (
		<div>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns, // '10% 15% 20% 25% 25%',
					textTransform: 'uppercase',
					backgroundColor: '#dadee2',
					fontSize: '12px',
					alignItems: 'center',
					padding: 10,
					textAlign: 'left',
					marginBottom: 1,
				}}
			>
				{columnDescriptions.map((column, i) => {
					return <span key={i}>{column.Title}</span>
				})}
			</div>
			{dataRows.map((row, index) => (
				<div
					key={keyField ? row[keyField]: index}
					style={{
						display: 'grid',
						gridTemplateColumns,
						padding: 5,
						textAlign: 'left',
						border: '1px solid #ededed',
						height: 35,
						alignContent: 'center',
						...dataRowStyle
					}}
					className={row.selected ? 'selectedContract': null}
					onClick={()=>onClickRow(row)}
				>
					{columnDescriptions.map((description, i) => (
						<span key={i}>{getCellContents(description, row)}</span>
					))}
				</div>
			))}
		</div>
	)
}
