/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getNextMeters, getContracts, submitContractSelection } from '../../../remoteResources/totecServerCalls'
import BusyIndicator from '../../library/busyIndicator'
import PageWrapper from '../../library/pageWrapper'
import TradeHeader from './tradeHeader'
import { DownStreamList, UpStreamList } from './downstreamList.js'
import ContractList from './contractList'
import Header from '../../header.js'
import ActionButton from '../../library/actionButton'
import ReturnButton from '../../library/returnButton'
import { AuthContext } from '../../context/auth'
import { GlobalStateContext } from '../../context/globalState'
import CollapsableInformation from '../../library/collapsableInformation'

export default function TradeDetails() {
	const selectedTradesState = useContext(GlobalStateContext).selectedTradesState
	const [selectedTrades] = selectedTradesState
	const navigate = useNavigate()
	const { meterNumber } = useParams()
	const authContext = useContext(AuthContext)

	let producer
	try{
		producer = authContext.context.code 
	} catch (ex){
		console.log('logged out')
		navigate('/')
	}


	const queryParams = new URLSearchParams(useLocation().search)
	const startDate = queryParams.get('startDate')
	const endDate = queryParams.get('endDate')
	const capacity = queryParams.get('capacity')
	const direction = queryParams.get('direction')
	const startingContractNumber = queryParams.get('selectedContract')
	const contractType = queryParams.get('contractType')
	const recurrentTrade = queryParams.get('recurrent')

	const [loading, setLoading] = useState(false)
	const [loadingDownStream, setLoadingDownStream] = useState(true)
	const [loadingUpstream, setLoadingUpstream] = useState(true)
	const [downStreamMeters, setDownstreamMeters] = useState()
	const [upStreamMeters, setUpstreamMeters] = useState()
	const [potentialContracts, setPotentialContracts] = useState([])
	const [selectedQuantity, setSelectedQuantity] = useState(0)

	const [currentMeterDetails, setCurrentMeterDetails] = useState({})
	const [enableSubmitSelection, setEnableSubmitSelection] = useState(false)

	async function loadDownStreamMeters() {
		setLoadingDownStream(true)
		const meters = await getNextMeters(meterNumber, capacity, startDate, endDate, 'in') // TODO: location number or meter number??
		setDownstreamMeters(meters)
		setLoadingDownStream(false)
	}

	useEffect(() => {
		loadDownStreamMeters()
	}, [])

	useEffect(() => {
		if (potentialContracts == null) return
		let total = 0
		potentialContracts.forEach((record) => {
			if (!!record.selected) {
				// console.log('record', record)
				total += parseFloat(record['Surplus/Deficit'])
			}
		})
		setSelectedQuantity(total.toFixed(1))
		// if a contract has been selected enable the button
		setEnableSubmitSelection(potentialContracts.some((record) => !!record.selected))
	}, [potentialContracts])

	async function loadUpStreamMeters() {
		setLoadingUpstream(true)
		const meters = await getNextMeters(meterNumber, capacity, startDate, endDate, 'out') // TODO: location number or meter number??
		setUpstreamMeters(meters)
		setLoadingUpstream(false)
	}
	useEffect(() => {
		loadUpStreamMeters()
	}, [])

	async function loadContracts() {
		setLoading(true)
		const contracts = (await getContracts(
			meterNumber,
			capacity,
			startDate,
			endDate,
			direction,
			startingContractNumber,
			contractType
		)).sort((a,b)=>{
			return a.rates.typeA - b.rates.typeA
		})
		contracts.forEach(contr => {
			if (selectedTrades.includes(contr.Contract_number))
				contr.selected = true
		})

		setPotentialContracts(contracts)
		setLoading(false)
	}
	useEffect(() => {
		loadContracts()
	}, [])

	useEffect(() => {
		setLoadingDownStream(true)
		if (downStreamMeters?.[0] === undefined) return
		for (let i = 0; i < downStreamMeters.length; i++) {
			if (downStreamMeters[i]?.meterNumber === parseInt(meterNumber)) {
				setCurrentMeterDetails({
					segment: downStreamMeters[i].segment,
					meterNumber: downStreamMeters[i].meterNumber,
					meterName: downStreamMeters[i].meterName,
				})
				setLoadingDownStream(false)
				return
			}
		}
		setLoadingDownStream(false)
	}, [downStreamMeters])

	function goEdit() {
		navigate('/')
	}

	async function submitContractChoices() {
		const selectedContracts = potentialContracts.filter((contract) => contract.selected)

		const orderId = await submitContractSelection({
			selectedContracts,
			currentMeterDetails,
			startDate,
			endDate,
			direction,
			capacity,
			contractType,
			recurrentTrade,
			selectedQuantity,
			startingContractNumber,
			producer
		})
		console.log(orderId)
		navigate(`/confirmation/${orderId}`)
	}
	
	return (
		<>
			<Header />
			<PageWrapper>
				<div style={{ width: '100%', backgroundColor: 'white', height: '100vh', paddingLeft: '10px' }}>
					<div style={{ width: '98%', display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
						<div
							style={{
								fontSize: 18,
								color: '#3f4e5a',
								textTransform: 'uppercase',
								textDecoration: 'none solid rgb(63, 78, 90)',
							}}
						>
							requirements
						</div>
						<ReturnButton onClick={goEdit}>
							Edit requirements
						</ReturnButton>
					</div>
					{!loading && (
						<div style={{ width: '98%' }}>
							<TradeHeader
								capacity={capacity}
								startDate={startDate}
								endDate={endDate}
								direction={direction}
								contract={startingContractNumber}
								recurrent={recurrentTrade}
								currentMeter={currentMeterDetails}
								contractType={contractType}
							/>
						</div>
					)}
					{loading && <BusyIndicator />}

					{/* <br /> */}
					{!loading && (
						<div style={{ width: '98%' }}>
							<ContractList
								potentialContracts={potentialContracts}
								selectedTradesState={selectedTradesState}	
								setPotentialContracts={(foo) => {
									setPotentialContracts(foo)
								}}
							/>
						</div>
					)}

					<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
						<ActionButton
							// enabled={!!selectedStationNumber && !!txType && !!selectedContractType && !!recurringTrade}
							enabled={enableSubmitSelection}
							onClick={submitContractChoices}
						>
							Submit selection
						</ActionButton>
					</div>
					<CollapsableInformation>
						<div
							style={{
								width: '95%',
								marginTop: 15,
								border: '1px solid grey',
								borderRadius: 12,
								backgroundColor: 'Seashell',
								padding: 18,
							}}
						>
							<h3 style={{ backgroundColor: 'lightBlue', height: 50 }}>
								Selected Quantity: {selectedQuantity}/{capacity}
							</h3>
							{!loadingDownStream && <DownStreamList nextMeters={downStreamMeters} />}
							{!loadingUpstream && <UpStreamList nextMeters={upStreamMeters} />}
						</div>
					</CollapsableInformation>
				</div>
			</PageWrapper>
		</>
	)
}
