import { BrowserRouter, Routes, Route } from 'react-router-dom'
import TradeDetailsEntry from './components/naturalGas/tradeDetailsEntryPage/tradeDetailsEntry.js'
import TradeDetails from './components/naturalGas/tradeDetailsPage/tradeDetails.js'
import Confirmation from './components/naturalGas/confirmationPage/confirmation.js'
import LoginPage from './loginPage.js'
import AuthProvider from './components/context/auth.js'
import GlobalStateProvider from './components/context/globalState.js'
import './App.css'
import RequiresAuth from './components/library/requiresAuth.js'


function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<AuthProvider>
					<GlobalStateProvider>
						<Routes>
							{/* <Header /> */}
							<Route path='/' element={<RequiresAuth><TradeDetailsEntry/></RequiresAuth>} />
							<Route path='/tradeDetails/:meterNumber' exact element={<RequiresAuth><TradeDetails/></RequiresAuth>} />
							<Route path='/confirmation/:orderId' element={<RequiresAuth><Confirmation/></RequiresAuth>} />
							<Route path='/login' element={<LoginPage/>} />
						</Routes>
					</GlobalStateProvider>
				</AuthProvider>
			</BrowserRouter>
		</div>
	)
}

export default App
