/* eslint-disable react-hooks/exhaustive-deps */
import { createContext,  useState, useEffect } from "react"
import endOfMonth from 'date-fns/endOfMonth'

const GlobalStateContext = createContext()

const GlobalStateProvider = ({children}) =>{
    const selectedStationNumberState = useState(null)
	const selectedStationState = useState(null)
	const capacityState = useState(50)
	const recurringTradeState = useState('no')
	const txTypeState = useState('buy')
	const selectedContractState = useState(null)
	const startDateState = useState(new Date())
	const endDateState = useState(endOfMonth(new Date()))
	const availableContractTypesState = useState(['a', 'b', 'c'])
	const selectedContractTypeState = useState(null)
	const optionsState = {
		selectedStationNumberState,
		selectedStationState,
		capacityState,
		recurringTradeState,
		txTypeState,
		selectedContractState,
		startDateState,
		endDateState,
		availableContractTypesState,
		selectedContractTypeState
	}
    const selectedTradesState = useState([])

	function resetAllGlobalState(){
		// const [value, setState] = useState, so directly calling the setter with the 
		selectedStationNumberState[1](null);
		selectedStationState[1](null);
		capacityState[1](50)
		recurringTradeState[1]('no')
		txTypeState[1]('buy')
		selectedContractState[1](null);
		startDateState[1](new Date())
		endDateState[1](endOfMonth(new Date()))
		availableContractTypesState[1](['a', 'b', 'c'])
		selectedContractTypeState[1](null);
        selectedTradesState[1]([])
	}
	useEffect(resetAllGlobalState, [])

    return <GlobalStateContext.Provider value={{...optionsState, selectedTradesState, resetAllGlobalState}} >
        {children}
    </GlobalStateContext.Provider>
}

export {GlobalStateContext}
export default GlobalStateProvider