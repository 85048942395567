// import '../../../App.css'

export default function ReturnButton({ children, onClick, style }) {
    return <button
        className='editButton'
        style={{
            width: 140,
            height: 30,
            borderRadius: 3,
            border: '1px solid #3f4e5a',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            fontSize: 13,
            color: '#3f4e5a',
            ...style
        }}
        onClick={onClick}
    >
        {children}
    </button>
}
