/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import PageWrapper from '../../library/pageWrapper.js'
import { getGasMetersForCompany } from '../../../remoteResources/totecServerCalls.js'
import BusyIndicator from '../../library/busyIndicator.js'
import ActionButton from '../../library/actionButton'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import CapacitySection from './capacitySection.js'
import DateSection from './dateSection.js'
import LocationSection from './locationSection.js'
import Header from '../../header.js'
import TradeType from './tradeType.js'
import { AuthContext } from '../../context/auth.js'
import { GlobalStateContext } from '../../context/globalState.js'
import '../../../App.css'

export default function TradeDetailsEntry() {
	const auth = useContext(AuthContext).context
	const options = useContext(GlobalStateContext)
	const navigate = useNavigate()


	let producer
	try{
		producer = auth.code 
	} catch (ex){
		console.log('logged out')
		navigate('/')
	}

	const {
		selectedStationNumberState,
		selectedStationState,
		capacityState,
		recurringTradeState,
		txTypeState,
		selectedContractState,
		startDateState,
		endDateState,
		availableContractTypesState,
		selectedContractTypeState
	} = options


	// useStates
	const [loading, setLoading] = useState(false)
	const [stations, setStations] = useState([])
	const [selectedStationNumber, setSelectedStationNumber] = selectedStationNumberState
	const [selectedStation, setSelectedStation] = selectedStationState
	const [capacity, setCapacity] = capacityState
	const [recurringTrade, setRecurringTrade] = recurringTradeState
	const [txType, setTxType] = txTypeState
	const [listOfContracts] = useState([])
	const [selectedContract, setSelectedContract] = selectedContractState
	const [startDate, setStartDate] = startDateState
	const [endDate, setEndDate] = endDateState
	const [availableContractTypes, setAvailableContractTypes] = availableContractTypesState
	const [selectedContractType, setSelectedContractType] = selectedContractTypeState
	
	// state handlers
	const stationChanged = (event) => {
		setSelectedStationNumber(event.value)
	}

	// Value of contract number in the location selection
	const contractChanged =  (event) => {

		// Find the first contract to match, there will always be 2 of each contract
		const firstMatchingContractTypeFromListOfContracts = listOfContracts.find(
			(contract) => contract.Contract_type === event.target.value
		)
		setSelectedContract(firstMatchingContractTypeFromListOfContracts)		
	}

	const txTypeChange = (value) => setTxType(value)
	const recurringTradeChange = (value) => setRecurringTrade(value)

	// Use Effects
	// When the start date changes, set the end date to the end of that month by default
	useEffect(() => {
		setEndDate(endOfMonth(startDate))
	}, [startDate])

	useEffect(() => {
		loadStations()
	}, [auth])

	useEffect(() => {
		const selectedStation = stations.find(station => station.number === selectedStationNumber)
		setSelectedStation(selectedStation)
		if (selectedStation == null) return

		let contractTypes = {}
		selectedStation.contracts.forEach(cntr => contractTypes[cntr.Contract_type] = true)
		contractTypes = Object.keys(contractTypes).sort()
		setAvailableContractTypes(contractTypes)
	}, [selectedStationNumber])

	useEffect(()=>{
		setSelectedContractType(availableContractTypes?availableContractTypes[0]:null)
	},
	[availableContractTypes])

	useEffect(()=>{
		if (selectedContractType == null || selectedStation == null) return
		const selectedContract = selectedStation.contracts.find(cntr => cntr.Contract_type === selectedContractType)
		setSelectedContract(selectedContract)
	}, [selectedContractType, selectedStation])

	// Data loaders
	async function loadStations() {
		setLoading(true)

		const otherMeters = await getGasMetersForCompany(producer)
		setStations(otherMeters)
		setLoading(false)
	}

	function redirectToRoutes() {
		navigate(
			`/tradeDetails/${selectedStationNumber}?capacity=${capacity}&startDate=` +
				`${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}&direction=${
					txType === 'buy' ? 'in' : 'out'
				}&selectedContract=${
					selectedContract.Contract_number || ''
				}&contractType=${selectedContract.Contract_type}&recurrent=${recurringTrade}`
		)
	}

	if (loading) return <BusyIndicator />

	return (
		<>
			<Header/>
			<PageWrapper>
				<div style={{ width: '100%', backgroundColor: 'white', height: '100%', paddingTop: 25 }}>
					<div
						style={{
							height: 50,
							margin: 0,
							display: 'grid',
							gridTemplateColumns: '1.5fr 5fr',
							alignItems: 'end',
							justifyItems: 'left',
							width: '95%',
							alignContent: 'end'
						}}
					>
						<div
							style={{
								fontSize: 20,
								textTransform: 'capitalize',
								textAlign: 'left',
								paddingLeft: 16,
								justifyContent: 'center',
								width: 225
							}}
						>
							<div style={{ height: '100%',justifyContent:'center'}}>TRANSPORT CAPACITY</div>
						</div>
						<TradeType
							txType={txType}
							txTypeChange={txTypeChange}
							recurringTrade={recurringTrade}
							recurringTradeChange={recurringTradeChange}
						/>
					</div>
					<CapacitySection capacity={capacity} setCapacity={setCapacity} />
					<DateSection
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
					/>
					<LocationSection
						selectedStationNumber={selectedStationNumber}
						stationChanged={stationChanged}
						stations={stations}

						availableContractTypes={availableContractTypes}
						selectedContractType= {selectedContractType}

						contractChanged={contractChanged}
						selectedContract={selectedContract}
					/>

					<div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 54 }}>
						<ActionButton
							onClick={redirectToRoutes}
							enabled={
								!!selectedStationNumber &&
								!!txType &&
								!!selectedContract?.Contract_type &&
								!!recurringTrade
							}
						>
							Find routes
						</ActionButton>
					</div>
				</div>
			</PageWrapper>
		</>
	)
}
