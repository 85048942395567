import { useNavigate } from 'react-router-dom'
import { useContext, useState} from 'react'
import { AuthContext } from './context/auth.js'
import { GlobalStateContext } from './context/globalState.js'
import { companies } from './library/companies.js'
import Select from 'react-select'

import PageWrapper from './library/pageWrapper'
export default function Header({ accountHolder, justLogo}) {
	const {context: auth, setContext: setAuth} = useContext(AuthContext)
	const resetGlobalState = useContext(GlobalStateContext).resetAllGlobalState
	const [options] = useState(companies.map(c => ({...c, value: c.code, label: c.name})))


	const navigate = useNavigate()
	function redirectToHome() {
		resetGlobalState()
		navigate('/')
	}

	return (
		<>
			<div style={{marginBottom: 25}}>
				<div style={{ paddingTop: 55}}>
					<img src='/Image.png' alt='logo' style={{ height: 105, cursor: 'pointer'}} onClick={redirectToHome} />
					{ !!accountHolder && <div style={{ textAlign:'right', paddingRight: '100px'}}>
						<span style={{ color: '#666666', textTransform: 'uppercase'}}>Account:</span> {accountHolder}
					</div>}
				</div>
				{!justLogo && 
					<PageWrapper>
						<div style={{width: '100%',display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
							<span style={{textTransform: 'uppercase', color: '#666666', marginRight: 15}}>Account:</span>
							{/* {auth?.name || "N/A"} */}
							{/* <span style={{paddingLeft: 10, borderWidth: 1, borderColor: "blue", borderStyle: 'solid'}}
								onClick={()=>setAuth(otherCompany)}
							>{otherCompany.code}</span> */}
							<span style={{width: 'max-content'}}>
							<Select 
								style={{}}
								options={options}
								onChange={(value)=>{
									setAuth(value)
									redirectToHome()
								}}
								value={auth}
							/></span>
						</div>

					</PageWrapper>
				}
			</div>
		</>
	)
}
