import React from 'react'

export default function TotectContact() {
	const rowStyle = { padding: 5 }
	return (
		<div
			style={{
				display: 'flex',
				width: 330,
				height: 149,
				flexDirection: 'column',
				justifyContents: 'flex-Start',
				textAlign: 'left',
				border: '1px solid #ccc',
				paddingTop: 17,
				paddingLeft: 34,
				boxShadow: ' 0px 1px 2px rgba(0,0,0,0.1)',
			}}
		>
			<div style={{ color: '#3f4e5a', paddingBottom:13 }}> To complete this transaction, contact:</div>
			<div style={{fontSize:16, fontWeight: 500, paddingBottom:5}}>TOTEC AI Energy</div>
			<div style={rowStyle}>transfers@totec.com</div>
			<div style={{ color: '#0088ff', fontWeight: 'bold', padding: 5 }}>1 (780) 345-6789</div>
		</div>
	)
}
