import SectionBox from '../../library/sectionBox.js'
import DatePicker from 'react-date-picker'
import endOfMonth from 'date-fns/endOfMonth'

export default function DateSection({ startDate, setStartDate, endDate, setEndDate }) {
	return (
		<SectionBox>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					<div style={{ padding: 8, paddingLeft: 0 }}>Start date:</div>

					<DatePicker
						value={startDate}
						onChange={setStartDate}
						clearIcon={null}
						minDate={new Date()}
						format='yyyy-MM-dd'
					/>
				</div>
				<div style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 35 }}>TO</div>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					<div style={{ padding: 8, paddingLeft: 0 }}>End date:</div>
					<DatePicker
						value={endDate}
						onChange={(date) => setEndDate(endOfMonth(date))}
						clearIcon={null}
						minDate={new Date()}
						format='yyyy-MM-dd'
					/>
				</div>
			</div>
		</SectionBox>
	)
}
