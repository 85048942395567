import { createContext,  useState, useEffect } from "react"
import { postLogin } from "../../remoteResources/totecServerCalls"
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext()

const AuthProvider = ({children}) =>{
	const navigate = useNavigate()

    async function tryLogin(userName, password) {
        console.log('try login', userName, password)
        try {
            let result = await postLogin(userName, password)
            result = {...result, label: result.name, value: result.code}
            setContext(result)
            navigate('/')
        } catch (ex) {
            throw ex
        }
    }
    const [context, setContext] = useState()

    useEffect(()=>{
        console.log('**** context is now', context)
    }, [context])


    return <AuthContext.Provider value={{context, setContext, tryLogin}} >
        {children}
    </AuthContext.Provider>
}

export {AuthContext}
export default AuthProvider