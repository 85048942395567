import React from 'react'
import Header from '../header.js'

export default function BusyIndicator() {
	return (
		<div>
			<Header />
			 <img src="/spinner.gif" alt="logo" style={{height:150, marginTop:150}}/>
		</div>
	)
}
