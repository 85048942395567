import React from 'react'
import TotecTable from '../../library/totecTable'
import ConfirmationMathDetails from './confirmationMathDetails'
import TradeDirectionDetails from './tradeDirectionDetails'
import {dollarFormatter} from '../../library/formatters'


export default function SampleConfirmation({ orderDetails }) {
	const { order } = orderDetails
	const {producer, producerName} = order
	const dataRows = orderDetails.order.selectedContracts
    const listOfAddedContracts = []

	const oppositePerspectiveColumnDescriptions = [
		{ Title: 'METER', key: 'Meter', width: '.6fr' },
		{ Title: 'Contract #', key: 'Contract_number', width: '1.25fr' },
		{
			Title: 'CONTRACT TYPE',
			formatter: (record) => record.Contract_type.toUpperCase(),
			key: 'Contract_type',
			width: '.8fr',
		},
		{
			Title:
				orderDetails.order.direction === 'in' ? (
					<>
						UNUTILIZED (<span style={{ textTransform: 'lowercase' }}>e3m3</span>)
					</>
				) : (
					<>
						Required (<span style={{ textTransform: 'lowercase' }}>e3m3</span>)
					</>
				),
			// key: 'Surplus/Deficit',
			formatter: r=>Math.abs(+r['Surplus/Deficit']),
			width: '1fr',
		},
		{
			Title: (
				<>
					TRANSFER
					<br /> {orderDetails.order.direction === 'in' ? 'out ' : 'in '}(
					<span style={{ textTransform: 'lowercase' }}>e3m3</span>)
				</>
			),
			key: 'transferAmount',
			width: '.8fr',
			formatter: (record) => (record?.transferAmount ? record.transferAmount : 0),
		},
		{ Title: 'NET', key: 'net', width: '.5fr', formatter: (record) => (record?.net ? record.net : 0) },
		{ Title: 'Meter To', key: 'check', width: '.8fr',formatter: () => order.currentMeterDetails.meterNumber},
		{ Title: 'COMPANY', key: 'check1', width: '2fr', formatter: () => producerName },
		{ Title: 'COMPANY CODE', key: 'check2', width: '.8fr', formatter: () => producer },
		{
			Title: (
				<>
					FEE
					<br />
					<span style={{ textTransform: 'lowercase' }}>e3m3/mth</span>
				</>
			),
			formatter: record=> {
				return orderDetails.order.direction==='in'? record['formattedTotalFeeOut']: dollarFormatter(record['feePerMthIn'])
			},
			width: '.8fr',
		},
	]
    

	return (
		<>
			{
            dataRows.map((contract, i) => {
                if(listOfAddedContracts.includes(contract.Producer)){
                    console.log( 'already here!')
                    return null
                }
                listOfAddedContracts.push(contract.Producer)

				const contractsByProducer = dataRows.filter((row) => row.Producer === contract.Producer)
				return (
					<div
						style={{
							border: '4px solid #ccc',
							borderRadius: 3,
							minHeight: 20,
							marginTop: 20,
							marginBottom: 20,
							padding: 27,
							paddingBottom: 8,
							width: 960
						}}
						key={contract.Contract_number}
					>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
							<TradeDirectionDetails
								direction={orderDetails.order.direction === 'in' ? 'out' : 'in'}
								orderDetails={orderDetails}
								startDate={new Date(orderDetails.order.startDate)}
								endDate={new Date(orderDetails.order.endDate)}
								contractNumber={contract.Contract_number}
								company={contract.Producer}
								companyName={contract.ProducerName}
							/>
							<div style={{ textAlign: 'right' }}>
								* Confirmation that would be sent to {orderDetails.order.direction === 'in' ? 'Seller: ': 'Buyer: '} <span style={{fontWeight:600}}>{contract.Producer}</span>
							</div>
						</div>
						<div style={{ marginTop: 20 }}>
							<TotecTable
								columnDescriptions={oppositePerspectiveColumnDescriptions}
								dataRows={contractsByProducer}
								keyField={'Contract_number'}
							/>
							<ConfirmationMathDetails orderDetails={orderDetails} relatedDetails/>
						</div>
					</div>
				)
			})}
		</>
	)
}
