import httpRequests from './httpRequests'
const { get, post } = httpRequests

export async function getGasMeters() {
	const stations = await get('/meters')
	return stations
}

export async function getGasMetersForCompany(companyCode){
	const stations = await get(`/metersForCompany/${companyCode}`)
	return stations
}

export async function getNextMeters(locationNumber, capacity, startDate, endDate, direction) {
	const url = `/pathing/${locationNumber}?capacity=${capacity}&startDate=${startDate}&endDate=${endDate}&direction=${direction}`
	const contracts = await get(url)
	return contracts
}

export async function getContracts(locationNumber, capacity, startDate, endDate, direction, selectedContract, contractType) {
	const url = `/contracts/${locationNumber}?capacity=${capacity}&startDate=${startDate}&endDate=${endDate}&direction=${direction}&selectedContract=${selectedContract ||''}&contractType=${contractType}`
	const contracts = await get(url)
	return contracts
}

// gets only the contract numbers for that selected meter
export async function getSelectedMeterContracts(meterNumber){
	const url = `/targetMeterContracts/${meterNumber}`
	const returnedContractNumbers = await get(url)
	return returnedContractNumbers
}

export async function submitContractSelection(selectedContracts) {
	const url = `/confirmationContracts`
	const returnedContractNumbers = await post(url, selectedContracts)
	return returnedContractNumbers
}

export async function getConfirmationDetails(id){
	const url = `/confirmationDetails/${id}`
	const confirmationDetails = await get(url)
	return confirmationDetails
}

export async function getMeterDetails(meterNumber){
	const url = `/meterDetails/${meterNumber}`
	const meterDetails = await get(url)
	return meterDetails
}

export async function getContractDetails(contractNumber, meterNumber){
	const url = `/contractDetails/${contractNumber}/${meterNumber}`
	const contractDetails = await get(url)
	return contractDetails
}

export async function postLogin(userName, password){
	const url ="/login"
	try{ 
		const credentials = await post(url, {userName, password})
		return credentials
	} catch(ex) {
		throw ex
	}
}