import { useContext, useEffect } from 'react'
import { AuthContext } from '../context/auth.js'
import { useNavigate } from 'react-router-dom'

function RequiresAuth ({children}){
    const auth = useContext(AuthContext).context
    const navigate = useNavigate()

    useEffect(()=>{
        if (!auth?.code)
            navigate('/login')
    }, [auth?.code, children, navigate])

    return <>
        {children}
    </>
}

export default RequiresAuth