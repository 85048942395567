export default function TradeType({ txType, txTypeChange, recurringTrade, recurringTradeChange }) {
	const radioLayout = {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		padding: '5px 0px 0px 0px',
		alignContents: 'center',
	}
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(2, 1fr)',
				width: '100%',
				marginRight: 10,
				height: '100%',
			}}
		>
			<div style={{...radioLayout, marginLeft: 45, marginRight: 35}}>
				<div>
					Transfer: <span style={{ color: 'red' }}>*</span>
				</div>
				<div onClick={()=>txTypeChange('buy')} style={{cursor: 'pointer'}}>
					<input 
						type='radio' value='buy' name='txType' checked={txType === 'buy'} 
						onChange={()=>{}} 
					/>{' '}
					IN
				</div>
				<div onClick={()=>txTypeChange('sell')} style={{cursor: 'pointer'}}>
					<input
						type='radio'
						value='sell'
						name='txType'
						checked={txType === 'sell'}
						onChange={()=>{}}
					/>{' '}
					OUT
				</div>
			</div>

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1.5fr 1fr 1fr',
					borderLeft: '1px solid #ccc',
					alignItems: 'end',
					alignContent: 'center',
					paddingLeft: 55,
					paddingRight: 25
				}}
			>
				<div>
					Recurrent: <span style={{ color: 'red' }}>*</span>
				</div>
				<div onClick={()=>recurringTradeChange('no')} style={{cursor: 'pointer'}}>
					<input
						type='radio'
						value='no'
						name='recurringTrade'
						checked={recurringTrade === 'no'}
						onChange={()=>{}}
					/>{' '}
					NO
				</div>

				<div onClick={()=>recurringTradeChange('yes')} style={{cursor: 'pointer'}}>
					<input
						type='radio'
						value='yes'
						name='recurringTrade'
						checked={recurringTrade === 'yes'}
						onChange={()=>{}}
					/>{' '}
					YES
				</div>
			</div>
		</div>
	)
}
