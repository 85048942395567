import React from 'react'
import { differenceInDays } from 'date-fns'

export default function TradeDirectionDetails({ direction, startDate, endDate, contractNumber, company, companyName  }) {
	// console.log
	startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset())
	endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset())
	console.log('Start: ', startDate.toString())
	const effectiveDays = differenceInDays(endDate, startDate) + 1
	const term = `${startDate.toLocaleString('default', { month: 'long' })} ${startDate.getFullYear()}`

	const confirmationStyle = {
		color: '#666666',
		borderBottom: '1px solid #dadee2',
		// display:'flex',
		// alignItems: 'space-between',
		// justifyContent: 'end',
		// paddingTop: 15,
		width: '90%',
		textAlign: 'right',
		marginTop: 15,
	}

	const boldDetails = {
		fontSize: 16,
		fontWeight: 600,
		color: '#000',
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '.3fr 1fr',
				height: 250,
				border: '1px solid #dadee2',
				borderRadius: 5,
				width: 420, // change to 250
				boxShadow: ' 0px 1px 6px #dadee2',
			}}
		>
			<div
				style={{
					backgroundColor: '#3f4e5a',
					color: '#fff',
					paddingTop: 20,
					borderRadius: '5px 0px 0px 5px',
					width: '100%',
					font: 16,
				}}
			>
				Transfer <br />
				<div style={{ fontSize: 18, marginTop: 10, textTransform: 'uppercase', fontWeight: 600 }}>
					{direction}
				</div>
			</div>
			<div
				style={{
					display: 'grid',
					gridTemplateRows: '1.5fr 4fr',
					alignItems: 'center',
					width: '100%',
					marginLeft: 0,
				}}
			>
				<div
					style={{
						backgroundColor: '#edf0f1',
						height: '100%',
						width: '100%',
						display: 'grid',
						borderBottom: '2px solid #dadee2',
						alignContent: 'center',
					}}
				>
					<div style={{ width: '90%', textAlign: 'right' }}>
						<div style={{ paddingBottom: 10 , fontSize:14}}>Confirmation #</div>
						<div style={{ fontWeight: 700 }}>
							{direction.toUpperCase()}-{contractNumber}
						</div>
					</div>
				</div>
				<div style={{ height: '100%', display: 'grid', justifyItems: 'start', width: '100%' }}>
					<div style={confirmationStyle}>
						Term: <span style={boldDetails}>{term}</span>
					</div>
					<div style={confirmationStyle}>
						Effective Days: <span style={boldDetails}>{effectiveDays}</span>
					</div>
					<div style={confirmationStyle}>
						Company: <span style={boldDetails}>{companyName}</span>
					</div>
					<div
						style={{
							color: '#666666',
							// paddingTop: 15,
							width: '90%',
							textAlign: 'right',
							marginTop: 15
						}}
					>
						Company Code: <span style={boldDetails}>{company}</span>
					</div>
				</div>
			</div>
		</div>
	)
}
