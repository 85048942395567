import SectionBox from '../../library/sectionBox.js'
import '../../styles/input.css'

export default function CapacitySection({ capacity, setCapacity }) {
	return (
		<SectionBox
			style={{
				display: 'grid',
				gridTemplateColumns: '1fr',
				gridColumnGap: '5px',
				marginTop: 10,
			}}
		>
			<div
				style={{
					height: '100%',
					alignItems: 'left',
				}}
			>
				<div style={{ textAlign: 'left', paddingTop: '18px' }}>
					Quantity <span style={{ color: '#666666' }}>(e³m³)</span>:
					<span style={{ color: 'red' }}> *</span>
				</div>
				<div
					className="anInput"
					style={{
						border: '1px solid #ccc',
						width: 135,
						height: 55,
						marginTop: 16,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<input
						type='number'
						className="hideBorderOnFocusInput"
						name='capacity'
						id='capacity'
						min='0'
						step={0.5}
						style={{
							width: '100%',
							borderRadius: 2,
							fontSize: 16,
							paddingLeft:14,
							border: 'none',
							WebkitAppearance: 'none',
							MozAppearance: 'none',
						}}
						value={capacity}
						onChange={(e) => {
							let val = Math.abs(e.target.value)
							if (val !== Math.round(val))
								val = Math.round(val*2)/2
							return setCapacity(val)
						}
						}// get the absolute number of the capacity
					/>{' '}
					{/* <span style={{ color: '#666666' }}>e³m³</span> */}
				</div>
			</div>
		</SectionBox>
	)
}
