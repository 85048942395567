import SectionBox from '../../library/sectionBox.js'

const labelStyle = { fontSize: '14px', color: '#666666', paddingTop: 10 }
const valueStyle = { fontSize: '12px', color: '000', fontWeight: 600 }

export default function TradeHeader({
	capacity,
	startDate,
	endDate,
	direction,
	contract,
	recurrent,
	currentMeter,
	contractType,
}) {

	return (
		<div style={{ height: 90 }}>
			<SectionBox style={{ minHeight: 30, paddingLeft: 10, paddingRight: 10, margin: '10px 0px 10px 0px' }}>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '.7fr 1fr 2fr 2fr 1fr  1fr 1fr', // add another fr if we add the contract back in
						gridTemplateRows: 'repeat(2, 1fr)',
						gridColumnGap: 5,
						gridRowGap: 10,
						width: '98%',
						borderRadius: '5px',
						justifyItems: 'left', // align things left-right
						textAlign: 'left',
					}}
				>
					{/* Labels */}
					<div style={labelStyle}>Transfer:</div>
					<div style={labelStyle}>Qty (e³m³):</div>
					<div style={labelStyle}>Date range:</div>
					<div style={labelStyle}>Location:</div>
					<div style={labelStyle}>Recurrent:</div>
					<div style={labelStyle}>Segment:</div>
					<div style={labelStyle}>Type:</div>

					{/* Values */}
					<div style={valueStyle}>{direction.toUpperCase()}</div>
					<div style={valueStyle}>{capacity}</div>
					<div style={valueStyle}>{`${startDate} - ${endDate}`}</div>
					<div style={valueStyle}>{`${currentMeter.meterNumber} - ${currentMeter.meterName}`}</div>
					<div style={valueStyle}>{recurrent.toUpperCase()}</div>
					<div style={valueStyle}>{currentMeter.segment}</div>
					<div style={valueStyle}>{contractType.toUpperCase()}</div>
				</div>
			</SectionBox>
		</div>
	)
}
