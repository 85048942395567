import React from 'react'

export default function PageWrapper({ children }, justHeader) {
	return (
		<div
			style={{
				// backgroundColor: 'lightgrey',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'stretch'
			}}
		>
			<div style={{ width: 900}}>{children}</div>
		</div>
	)
}
