/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Header from '../../header.js'
import PageWrapper from '../../library/pageWrapper.js'
import BusyIndicator from '../../library/busyIndicator.js'
import { getConfirmationDetails } from '../../../remoteResources/totecServerCalls.js'
import TotectContact from '../../library/totectContact.js'
import TradeDirectionDetails from './tradeDirectionDetails.js'
import ContractReview from './contractReview.js'
import SampleConfirmation from './sampleConfirmation.js'
import ReturnButton from '../../library/returnButton.js'

export default function Confirmation() {
	const navigate = useNavigate()
	const { orderId } = useParams()
	const [loading, setLoading] = useState(true)
	const [orderDetails, setOrderDetails] = useState({})

	// on page load call server to get order details
	useEffect(() => {
		loadConfirmationDetails()
	},[])

	// data loading
	async function loadConfirmationDetails() {
		setLoading(true)
		const details = await getConfirmationDetails(orderId)
		setOrderDetails(details)
		setLoading(false)
	}

	if (loading) return <BusyIndicator />
	return (
		<>
			<Header />
			<PageWrapper>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<ReturnButton onClick={() => console.log('clicky') || navigate(-1)} style={{ width: 200 }}>
						Return to Route Selection
					</ReturnButton>
				</div>
				<div
					style={{ textAlign: 'left', fontSize: 18, textTransform: 'Uppercase', margin: '40px 0px 10px 0px' }}
				>
					Contract Details
				</div>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '200px' }}>
					{!loading && (
						<TradeDirectionDetails
							direction={orderDetails.order.direction}
							orderDetails={orderDetails}
							startDate={new Date(orderDetails.order.startDate)}
							endDate={new Date(orderDetails.order.endDate)}
							contractNumber={orderDetails?.startingContract?.Contract_number || '!!!'}
							company={orderDetails?.order?.producer || '----'}
							companyName={orderDetails?.order?.producerName || '----'}
						/>
					)}
					<TotectContact />
				</div>
				<div style={{ marginTop: 50 }}>{!loading && <ContractReview orderDetails={orderDetails} />}</div>
				<div style={{ marginTop: 50, marginBottom: 50, display: 'grid', justifyContent: 'center' }}>
					{!loading && <SampleConfirmation orderDetails={orderDetails} />}
				</div>
				<br />
			</PageWrapper>
		</>
	)
}
