import {useState} from 'react'

function CollapsableInformation({children}){
    const [isOpen, setIsOpen] = useState(false)
    return <div style={{paddingBottom: 25, marginTop: 15}}>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', opacity: isOpen?.6:.2, cursor: 'pointer'}}>
            <img src='/info.png' alt='logo' style={{ height: 35}} onClick={()=>setIsOpen(!isOpen)} />
        </div>
        {isOpen && children}
    </div>
}

export default CollapsableInformation