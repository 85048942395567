import React from 'react'
import TotecTable from '../../library/totecTable.js'
export default function ContractList({ potentialContracts, setPotentialContracts, selectedTradesState }) {
	const [, setSelectedTrades] = selectedTradesState

	function handleCheckBoxChange(row){
		const checked = !!row.selected
		row.selected = !checked
		const newContracts = [...potentialContracts]
		setPotentialContracts(newContracts)
		setSelectedTrades(potentialContracts.filter(contr => contr.selected).map(contr=>contr.Contract_number))
	}
	const columnDescription = [
		{
			Title: '',
			formatter: (record) => {
				// console.log('....', !!record.selected)
				return (
					<input
						type='checkbox'
						value={record['Surplus/Deficit']}
						// onChange={(e) => handleCheckBoxChange(e, record)}
						onChange={()=>{}}
						checked={!!record.selected}
						style={{height: 20, width:20}}
					/>
				)
			},
			width: '.5fr',
		},
		// {Title: 'COST', key: 'cost', width: '1.2fr'},
		{
			Title: (
				<>
					Available
					<br />
					QTY <span style={{textTransform: 'none'}}>(e<sup>3</sup>m<sup>3</sup>)</span>
				</>
			),
			key: 'Surplus/Deficit',
		},
		{
			Title: (
				<>
					FT-R Rate
					<br/>
					<span style={{textTransform: 'none'}}>e<sup>3</sup>m<sup>3</sup>/day</span>
				</>
			),
			width: '.9fr',
			formatter: (record) => `${record.rates[`type${record.Contract_type.toUpperCase()}`]}`,
		},

		// {Title: 'Date Range:', formatter: ((record)=>('n/a'))},
		{ Title: 'Contract #', key: 'Contract_number', width: '1.5fr' },
		{
			Title: <>Contract<br/>Type</>,
			key: 'Contract_type',
			width: '.8fr',
			formatter: (record) => record.Contract_type.toUpperCase(),
		},
		{ Title: 'Meter', key: 'Meter', width: '1fr' },
		// { Title: 'Meter Type', formatter: (record) => `${record.meterType}` },
		{ Title: 'Segment', key: 'Segment', width: '.75fr' },
		// { Title: 'distance', formatter: (x)=>{
		// 	console.log('%c@@@', 'color: yellow; background: purple', x.distance)
		// 	return x.distance.toFixed(1)
		// }}
	]

	function onRowClick(row) {
		console.log('%c###', 'color: yellow', row)
		handleCheckBoxChange(row)
	}

	return (
		<div style={{ marginBottom: 80 }}>
			<TotecTable
				columnDescriptions={columnDescription}
				dataRows={potentialContracts.sort((a,b)=> a.distance-b.distance)}
				style={{ backgroundColor: 'blue' }}
				keyField={'Contract_number'}
				dataRowStyle ={{cursor: 'pointer'}}
				onClickRow = {onRowClick}
			/>
		</div>
	)
}
