import TotecTable from '../../library/totecTable.js'
const columnDescription = [
	{ Title: 'Meter Name', formatter: (record) => `${record.meterNumber} - ${record.meterName}`, width: '2.5fr' },
	{ Title: 'Meter Number', formatter: (record) => `${record.meterNumber}` },
	{ Title: 'Meter Type', formatter: (record) => `${record.meterType}` },
	{ Title: 'Segment #:', key: 'segment', width: '.75fr' },
	{ Title: 'Distance', width: '.75fr', formatter: (record) => {
		const style = record.distance >= 15 ? {} : 
			{backgroundColor: '#0002', color: 'white', width:35, borderRadius: 40, padding: 4}
		return <div style={{...style, paddingLeft: 10}}>{`${(+record.distance).toFixed(1)}`}</div>}
	},
	{ Title: 'Bearing', width: '.75fr', formatter: (record) => `${(+record.bearing).toFixed(2)}`},
]

export function DownStreamList({ nextMeters }) {
	return (
		<div style={{ padding: 20, backgroundColor: 'pink', marginTop: 20 }}>
			<h2>Could match my Transfer OUT</h2>
			<div>They could Transfer IN</div>
			<div>Downstream (bearing 60-300), or within 15km (including starting meter)</div>
			<TotecTable columnDescriptions={columnDescription} dataRows={nextMeters} keyField={'meterNumber'} />
		</div>
	)
}

export function UpStreamList({ nextMeters }) {
	return (
		<div style={{ padding: 20, backgroundColor: 'lightblue', marginTop: 20 }}>
			<h2>Could match my Transfer IN</h2>
			<div>They could Transfer OUT</div>
			<div>Upstream (bearing 0-60, 300-360), or within 15km (including starting meter)</div>
			<TotecTable columnDescriptions={columnDescription} dataRows={nextMeters} keyField={'meterNumber'} />
		</div>
	)
}
