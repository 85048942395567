import SectionBox from '../../library/sectionBox.js'
import Select from 'react-select'
import CollapsableInformation from '../../library/collapsableInformation.js'
import '../../styles/input.css'

export default function LocationSection({
	selectedStationNumber,
	stationChanged,
	stations,
	contractChanged,
	selectedContract,
	availableContractTypes,
	selectedContractType

}) {
	const meterOptions = stations.map((station) => {
		const words = station.name.split(' ').map(str => {
			// console.log(str)
			return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

		})
		// console.log(words.join(' '))
		const caseCorrected = words.join(' ')
		return { value: station.number, label: `${station.number} - ${caseCorrected}` }
	})

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			outline: 'none',
			border: 0,
		}),
		option: (provided, state) => ({
			...provided,
			padding: 10,
			textAlign: 'left',
			// textTransform: 'lowercase',
			fontSize: 16,
			outline: 'none'
		}),
		placeholder: (provided, state) => ({
			...provided,
			textAlign: 'left',
			outline: 'none'
		}),
		singleValue: (provided, state) => ({
			...provided,
			textAlign: 'left',
			padding: 10,
			fontSize: 16,
			color: '#202631',
			outline: 'none'
			// textTransform: 'lowercase',
		}),
	}

	return <>
		<SectionBox>
			<div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: 40 }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start',
					}}
				>
					<div style={{ marginBottom: 10 }}>
						Location (meter station): <span style={{ color: 'red' }}>*</span>
					</div>

					<div style={{ width: 330 }}>
						<Select
							className="anInput"
							styles={customStyles}
							value={meterOptions.find((opt) => opt.value === selectedStationNumber)}
							onChange={stationChanged}
							placeholder={'Location Name or Number'}
							options={meterOptions}
						/>
					</div>
				</div>
				<div
					className='test'
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start',
						width: 400,
					}}
				>
					<div style={{ marginBottom: 8 }}>
						Contract Type: <span style={{ color: 'red' }}>*</span>
					</div>
					<div
						style={{
							width: 200,
							display: 'grid',
							gridTemplateColumns: 'repeat(3, 1fr)',
							justifyItems: 'left',
						}}
					>
						<span style={{opacity: selectedStationNumber && availableContractTypes.includes('a') ? 1 : .2}}>
							<input
								type='radio'
								name='contractType'
								value='a'
								checked={selectedContractType === 'a'}
								onChange={contractChanged}
								
								disabled={!selectedStationNumber || !availableContractTypes.includes('a')}
							/>
							{' A'}
						</span>
						<span style={{opacity: selectedStationNumber && availableContractTypes.includes('b') ? 1 : .2}}>
							<input
								type='radio'
								name='contractType'
								value='b'
								checked={selectedContractType === 'b'}
								onChange={contractChanged}
								disabled={!selectedStationNumber}
							/>
							{' B'}
						</span>
						<span style={{opacity: selectedStationNumber && availableContractTypes.includes('c') ? 1 : .2}}>
							<input
								type='radio'
								name='contractType'
								value='c'
								checked={selectedContractType === 'c'}
								onChange={contractChanged}
								disabled={!selectedStationNumber}
							/>
							{' C'}
						</span>
					</div>

				</div>
			</div>
		</SectionBox>
		{selectedContract && <CollapsableInformation>
			<div style={{marginTop: 15, opacity: 0.5, backgroundColor: 'pink'}}>
				Contract number: {selectedContract.Contract_number}
			</div>
			</CollapsableInformation>
		}
	</>
}
