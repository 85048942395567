import React from 'react'
import '../../App.css'

export default function ActionButton({ children, onClick, enabled }) {
	onClick = onClick || (() => console.log('button has no implementation'))
	onClick = enabled ? onClick : (()=>console.log('button not enabled'))
	return (
		<div
		className='actionButton'
			style={{
				height: 42,
				width: 235,
				backgroundColor: '#3f4e5a',
				borderRadius: 3,
				color: 'white',
				fontSize: 16,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				opacity: enabled ? 1 : 0.3
			}}
			onClick={onClick}
		>
			{children}
		</div>
	)
}
