import React from 'react'
import TotecTable from '../../library/totecTable'
import ConfirmationMathDetails from './confirmationMathDetails'
import { dollarFormatter } from '../../library/formatters'

export default function ContractReview({ orderDetails }) {
	const dataRows = orderDetails.order.selectedContracts.map(mtr => mtr.originalContractCalculations)
	dataRows.forEach((record) => (record.selected = false)) // TODO: removed the selected flag for css, not sure this is the best approach
	const columnDescription = [
		{ Title: 'METER', key: 'Meter', width: '.8fr' },
		{
			Title: 'CONTRACT TYPE',
			formatter: (record) => record.Contract_type.toUpperCase(),
			key: 'Contract_type',
			width: '.8fr',
		},
		{
			Title:
				orderDetails.order.direction === 'in' ? (
					<>
						Required <br />(<span style={{ textTransform: 'lowercase' }}>e3m3</span>)
					</>
				) : (
					<>
						UNUTILIZED (<span style={{ textTransform: 'lowercase' }}>e3m3</span>)
					</>
				),
			key: 'Surplus/Deficit',
			width: '1fr',
		},
		{
			Title: (
				<>
					TRANSFER {orderDetails.order.direction}
					<br />(<span style={{ textTransform: 'lowercase' }}>e3m3</span>)
				</>
			),
			key: 'transferAmount',
			width: '1fr',
			formatter: (record) => (record?.transferAmount ? record.transferAmount : 0),
		},
		{ Title: 'NET', key: 'net', width: '.8fr', formatter: (record) => (record?.net ? record.net : 0) },
		{ Title: 'COMPANY', key: 'ProducerName', width: '2fr' },
		{ Title: 'COMPANY CODE', key: 'Producer', width: '.8fr' },
		{
			Title: (
				<>
					FEE
					<br />
					<span style={{ textTransform: 'lowercase' }}>e3m3/mth</span>
				</>
			),
			formatter: (record)=>{
				return dollarFormatter(orderDetails.order.direction === 'in' ? record.feePerMthIn:record.feePerMonthOut)
			},
			width: '1fr',
		},
	]

	return (
		<>
			<TotecTable columnDescriptions={columnDescription} dataRows={dataRows} />
			<ConfirmationMathDetails orderDetails={orderDetails}/>
		</>
	)
}
