import axios from 'axios'
const rootUrl = process.env.REACT_APP_SERVER

async function get(url, options) {
	try {
		url = `${rootUrl}${url}`
		console.log('fetching from', url)
		const result = await axios.get(url, options)
		if (result.status !== 200) throw new Error(result.statusText)
		return result.data
	} catch (err) {
		console.log('ERROR FROM GET CALL:', err)
		throw err
	}
}

async function post(url, data) {
    const options = {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		data: data,
		url,
	}
	try {
		url = `${rootUrl}${url}`
		console.log('fetching from', url)
		const result = await axios.post(url, options)
		if (result.status !== 200) throw new Error(result.statusText)
		return result.data
	} catch (err) {
		console.log('ERROR FROM POST CALL:', err)
		throw err
	}
}

const forExport = {get, post}
export default forExport